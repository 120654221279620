import React, { useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"
import "../static/css/header.css"
import logo from "../static/images/logo.png"

const SCROLL_THRESHOLD = 600
//https://snopkowski.com/blog/gatsby-navigation-styled-components

const Header = () => {
  useEffect(() => {
    const changeNavBg = e => {
      const scrollYPercentage =
        (window !== undefined
          ? window.scrollY > SCROLL_THRESHOLD
            ? 600
            : window.scrollY
          : 0) / SCROLL_THRESHOLD

      let navbar = document.getElementsByClassName("header")[0]
      navbar.setAttribute(
        "style",
        `background : rgb(${52 * scrollYPercentage}, ${
          73 * scrollYPercentage
        }, ${92 * scrollYPercentage})`
      )
    }
    document.addEventListener("scroll", changeNavBg)
    return () => {
      document.removeEventListener("scroll", changeNavBg)
    }
  }, [])

  return (
    <header className="header">
      <div className="container">
        <Link to="/" className="logo">
          <img src={logo} class="img-wrap" alt="" title="" />
        </Link>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" for="menu-btn">
          <span class="navicon"></span>
        </label>
        <ul className="menu">
          <li>
            <AnchorLink to="/#home">HOME</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#services">SERVICES</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#offers">OFFERS</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#testimonials">TESTIMONIALS</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#contact">CONTACT</AnchorLink>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header
