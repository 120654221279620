/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Banner from "./banner"
import Footer from "./footer"

import Contact from "./contact"

import "./layout.css"
import "../static/css/bootstrap.css"
import Location from "./location"

const Layout = ({ children, style }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <
      //contenteditable="true"
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <Banner siteTitle={data.site.siteMetadata.title} style={style} />
      {children}
      <Location />
      <Contact />
      <Footer />
    </>
  )
}

export default Layout
