import React from "react"
import "../static/css/banner.css"

import { AnchorLink } from "gatsby-plugin-anchor-links"

const Banner = ({ style }) => (
  <div class="bg" id="home" style={style}>
    <div class="container banner">
      <h2>SEQ Building Experts.</h2>
      <h3>We service Brisbane, the Gold Coast, & the Sunshine Coast</h3>
      <AnchorLink className="primary-btn" to="/#contact">
        BOOK FREE INSPECTION
      </AnchorLink>
      <h6>Scroll down to see more </h6>
    </div>
  </div>
)

export default Banner
