import React from "react"

const Form = () => (
  <form
    data-netlify="true"
    netlify-honeypot="bot-field"
    class="contact-form"
    name="contact"
    method="post"
    action="/thank-you"
  >
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />

    {/* <!-- Name --> */}
    <div class="mt-10">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Name"
        onFocus="this.placeholder = 'Name'"
        onblur="this.placeholder = 'Name'"
        required
        class="inut-sm single-input"
      />
    </div>

    {/* <!-- Service --> */}
    <select
      id="service-options"
      name="services"
      class="mt-10 single-input"
      required
    >
      <option value="" disabled selected>
        Select a Service
      </option>
      <option value="Free Inspection">Free Inspection</option>
      <option value="Chute Cleaning">Chute Cleaning</option>
      <option value="Chute Unblocking">Chute Unblocking</option>
      <option value="Bin Cleaning">Bin Cleaning</option>
      <option value="Strata Cleaning">Strata Cleaning</option>
      <option value="Home Cleaning">Home Cleaning</option>
      <option value="Car Park Cleaning">Car Park Cleaning</option>
      <option value="Custom Job">Custom Job - specify in message</option>
    </select>

    {/* <!-- CONTACT NUMBER --> */}
    <div class="mt-10">
      <input
        id="number"
        type="text"
        name="phone_number"
        placeholder="Contact No. "
        onFocus="this.placeholder = 'Contact No. '"
        onblur="this.placeholder = 'Contact No.'"
      />
    </div>

    {/* <!-- EMAIL --> */}
    <div class="mt-10">
      <input
        id="email"
        type="email"
        name="EMAIL"
        placeholder="Email address (if you prefer email)"
        onFocus="this.placeholder = ''(if you prefer email)"
        onblur="this.placeholder = 'Email address (if you prefer email)'"
      />
    </div>

    {/* <!-- Address --> */}
    <div class="mt-10">
      <input
        type="search"
        id="address"
        placeholder="Address"
        onFocus="this.placeholder = 'Address'"
        onblur="this.placeholder = 'Address'"
        required
      />
    </div>

    {/* <!-- MESSAGE --> */}
    <div class="mt-10">
      <textarea
        id="message"
        name="message"
        rows="4"
        placeholder="Message"
        onFocus="this.placeholder = 'Message'"
        onblur="this.placeholder = 'Message'"
      ></textarea>
    </div>

    <div class="mt-10 text-align-center">
      <button
        class="primary-btn dark-btn"
        type="submit"
        value="CONTACT TEAM  >"
      >
        BOOK SERVICE
      </button>
    </div>
  </form>
)

export default Form
