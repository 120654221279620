import PropTypes from "prop-types"
import React from "react"

import Form from "./form"

import "../static/css/contact.css"

const Contact = ({}) => (
  <section class="container mg-30" id="contact">
    <div class="row">
      <div class="col-md-12 text-align-center">
        <h2>Contact Us!</h2>
      </div>
    </div>
    <div class="row">
      <p>
        {"Address: "}
        <a href="https://www.google.com/maps/place/9+Portulaca+St,+Macgregor+QLD+4109">
          9 Portulaca Street, Macgregor, QLD, 4109
        </a>
        <br />
        {"Email Address: "}
        <a href="mailto:chuteoff@gmail.com">chuteoff@gmail.com</a> <br />
        Phone Number: <a href="tel:0413231255">0413 231 255</a>
        <br />
      </p>
    </div>
    <div class="row">
      <Form />
    </div>
  </section>
)

Contact.propTypes = {
  siteTitle: PropTypes.string,
}

Contact.defaultProps = {
  siteTitle: ``,
}

export default Contact
