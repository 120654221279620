import { Link } from "gatsby"
import React from "react"
import "../static/css/footer.css"

const Footer = () => (
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 pd-30">
          <h5>Top Services</h5>
          <ul class="footer-nav">
            <li>
              <Link to="/247-unblocking">ChuteOff 24/7 Unblocking</Link>
            </li>
            <li>
              <Link to="/chute-cleaning">ChuteOff Regular Chute Clean</Link>
            </li>
            <li>
              <Link to="/bin-cleaning">ChuteOff Bin Cleaning</Link>
            </li>
            <li>
              <Link to="/carpark-clean">ChuteOff Car Park Cleaning</Link>
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <div class="single-footer-widget newsletter">
            <h5>Service Locations</h5>
            <ul class="footer-nav">
              <li>
                <Link to="/chute-cleaning?brisbane">Brisbane</Link>
              </li>
              <li>
                <Link to="/chute-cleaning?sunshine-coast">Sunshine Coast</Link>
              </li>
              <li>
                <Link to="/chute-cleaning?gold-coast">Gold Coast</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div class="row ">
        <p class="col-lg-6 "></p>
        <div class="col-lg-4">
          <a
            href="https://www.facebook.com/ChuteOffAUSTRALLIA/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a href="#">
            <i class="fa fa-twitter"></i>
          </a>
          <a href="#">
            <i class="fa fa-linkedin"></i>
          </a>
          <a
            href="https://www.instagram.com/chuteoffaustralia/"
            target="_blank"
            rel="noreferrer"
          >
            <i class="fa fa-instagram"></i>
          </a>
        </div>
      </div> */}
    </div>
  </footer>
)

export default Footer
