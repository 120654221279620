import React from "react"

const Location = () => (
  <div className="container card mg-30">
    <div class="row">
      <div class="col-md-12 text-align-center">
        <h2>Brisbane, Gold Coast, Sunshine Coast!</h2>
        <h3>
          We service your location. If not, call us and we will organise
          something.
        </h3>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d907790.4770974242!2d153.1102212!3d-27.2801038!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2e0083e43bac7ce9!2sChuteOff%20Australia!5e0!3m2!1sen!2sau!4v1592978054606!5m2!1sen!2sau"
      width="100%"
      height="450"
      style={{
        boxShadow: "-5px -5px 12px 0px white, 0px 0px 5px 0px #34495c",
        borderRadius: "2em",
        marginTop: "1.5em",
        border: "none"
      }}
      title="Service Area"
      aria-hidden="false"
    ></iframe>
  </div>
)

export default Location
